import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "cultuar-admin-panel";

  constructor(public translate: TranslateService) {
    translate.addLangs(["en", "es", "fr"]);
    translate.setDefaultLang("es");
    console.log("version 1.0 - DEMO - 9/8/2022");
  }
}
