import { Action } from "@ngrx/store";
import { UserInformation } from "../models/userInformation.model";

export enum AppActionType {
  SET_USER_INFO = "[USER] LOGIN",
  SET_IS_SUPER_ADMIN = "[USER] SET SUPER ADMIN",
  SET_ORGANIZATION = "[ORGANIZATION] SELECTED",
  SET_OPTION_ORGANIZATION = "[ORGANIZATION OPTION] ENABLER",
  SET_POINT = "[POINT] SELECTED",
  SET_ROUTE = "[ROUTE] SELECTED",
  REMOVE_LANGUAGE = "[LANGUAGE] REMOVE",
}

export class SetUserInformationAction implements Action {
  readonly type = AppActionType.SET_USER_INFO;
  //add an optional payload
  constructor(public payload: UserInformation) {}
}

export class SetOrganization implements Action {
  readonly type = AppActionType.SET_ORGANIZATION;
  //add an optional payload
  constructor(public payload: any) {}
}

export class SetOptionInOrganization implements Action {
  readonly type = AppActionType.SET_OPTION_ORGANIZATION;
  //add an optional payload
  constructor(public payload: any) {}
}
export class SetRoute implements Action {
  readonly type = AppActionType.SET_ROUTE;
  //add an optional payload
  constructor(public payload: any) {}
}
export class SetIsSuperAdmin implements Action {
  readonly type = AppActionType.SET_IS_SUPER_ADMIN;
  //add an optional payload
  constructor(public payload: any) {}
}
export class RemoveLanguage implements Action {
  readonly type = AppActionType.REMOVE_LANGUAGE;
  //add an optional payload
  constructor(public payload: any) {}
}
