import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "./../services/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          this.authService.logout();
        }
        if (
          request.url.indexOf(environment.apiUrl) >= 0 &&
          err.status === 404
        ) {
          this.router.navigate(["/dashboard/404"]);
        }

        let error =
          err.error.error || err.error.message || err.statusText || err;

        if (
          err.error &&
          err.error.message &&
          err.error.errors &&
          err.error.errors[0]
        ) {
          error =
            err.error.message + " " + (err.error.errors && err.error.errors[0]);
        } else if (err.error && err.error.message) {
          error = err.error.message;
        }

        if (
          request.url.indexOf(environment.apiUrl) >= 0 &&
          err.status === 422 &&
          error == "validation.uuid"
        ) {
          this.router.navigate(["/dashboard/404"]);
        }

        return throwError(error);
      })
    );
  }
}
