import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class RolesService {
  selectedUser: any;
  userData: any;
  constructor(private http: HttpClient, private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  getRoles() {
    return this.http
      .get(`${environment.apiUrl}/roles`, {})
      .pipe(map((data) => data));
  }
}
