import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./modules/shared.module";

import { fakeBackendProvider } from "./core/interceptors/fake-backend.interceptor";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { LoadingInterceptor } from "./core/interceptors/loading.interceptor";

import { AuthGuardService } from "./core/guards/auth-guard.service";
import { AuthService } from "./core/services/auth.service";
import { OrganizationsService } from "./core/services/organizations.service";
import { PointsService } from "./core/services/points.service";
import { RoutesService } from "./core/services/routes.service";
import { UtilsService } from "./core/services/utils.service";
import { ConfigurationService } from "./core/services/configuration.service";

import { AppComponent } from "./app.component";
import { StyleguideComponent } from "./core/views/styleguide/styleguide.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { UsersService } from "./core/services/users.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgScrollbarModule } from "ngx-scrollbar";

import { AppReducer } from "./store/reducers/app.reducer";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { FilesService } from "./core/services/files.service";
import { LanguageService } from "./core/services/language.service";
import { OrganizationSelectionGuardService } from "./core/guards/organization-selection-guard.service";
import { RolesService } from "./core/services/roles.service";

@NgModule({
  declarations: [AppComponent, StyleguideComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxChartsModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    NgScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      AppState: AppReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  exports: [],
  providers: [
    AuthGuardService,
    OrganizationSelectionGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AuthService,
    OrganizationsService,
    PointsService,
    RoutesService,
    UsersService,
    MatSnackBarModule,
    UtilsService,
    ConfigurationService,
    FilesService,
    LanguageService,
    RolesService,
    //fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
