// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.demo.cultuar.es/api/v1",
  apiTest: "https://api.test.cultuar.beebitpruebas.com/api/v1",
  tokenValidTime: 7200000,
  geoapifyKey: "a65bc3c3a695421badfb39c576c675c0",
  openStreetMapKey:
    "pk.eyJ1IjoiYm9yamFtYXpvIiwiYSI6ImNrd2Npb2loNTBhaTUyb3F2bHRuN2dlc24ifQ.vkzmY4oju86JfSDRVQGo_A",
  mapBoxToken:
    "pk.eyJ1IjoiaXRhcnZpc2lvbiIsImEiOiJjbDBjOHpvbjQwNTZpM2NwMjFyc2ZzcTB1In0.kgjrAKe8uqconHUfhYo09w",
};
