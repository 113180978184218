import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { NavigationHistoryService } from "./navigation-history.service";

import { environment } from "@environments/environment";
import { User } from "@core/models/user";
import { AppState } from "@app/store/models/app-state.model";
import {
  SetIsSuperAdmin,
  SetOrganization,
  SetUserInformationAction,
} from "@app/store/actions/app.actions";

@Injectable({ providedIn: "root" })
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  private lastAccessSubject: BehaviorSubject<any>;
  private isSuperAdminSubject: BehaviorSubject<any>;
  private organizationSubject: BehaviorSubject<any>;
  public user: Observable<User>;
  public access: Observable<any>;
  public isSuperAdmin: Observable<boolean>;
  public organization: Observable<any>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private navigationHistoryService: NavigationHistoryService,
    private store: Store<AppState>
  ) {
    try {
      if (
        JSON.parse(localStorage.getItem("cultuar")) &&
        JSON.parse(localStorage.getItem("cultuar")).user
      ) {
        this.userSubject = new BehaviorSubject<User>(
          JSON.parse(localStorage.getItem("cultuar")).user
        );
        this.lastAccessSubject = new BehaviorSubject<string>(
          JSON.parse(localStorage.getItem("cultuar")).lastAccess
        );

        if (JSON.parse(localStorage.getItem("cultuar")).organization) {
          this.store.dispatch(
            new SetOrganization(
              JSON.parse(localStorage.getItem("cultuar")).organization
            )
          );
          this.organizationSubject = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem("cultuar")).organization
          );
        }

        if (
          JSON.parse(localStorage.getItem("cultuar")).hasOwnProperty(
            "isSuperAdmin"
          )
        ) {
          this.isSuperAdminSubject = new BehaviorSubject<boolean>(
            JSON.parse(localStorage.getItem("cultuar")).isSuperAdmin
          );
          this.store.dispatch(
            new SetIsSuperAdmin(
              JSON.parse(localStorage.getItem("cultuar")).isSuperAdmin
            )
          );
        }
      } else {
        this.userSubject = new BehaviorSubject<User>(null);
        this.lastAccessSubject = new BehaviorSubject<string>(null);
        this.isSuperAdminSubject = new BehaviorSubject<boolean>(false);
        this.organizationSubject = new BehaviorSubject<any>(null);
      }
    } catch (e) {
      console.error("error getting info from LocalStorage", e);
    }

    this.user = this.userSubject.asObservable();
    this.access = this.lastAccessSubject.asObservable();
    this.isSuperAdmin = this.isSuperAdminSubject.asObservable();
    this.organization = this.organizationSubject.asObservable();
    //this._apiRoute = environment.apiUrl;
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public get getIsSuperAdmin(): boolean {
    return this.isSuperAdminSubject.value;
  }

  public get lastAccess(): any {
    return this.lastAccessSubject.value;
  }

  public set lastAccess(time) {
    this.lastAccessSubject.next(new Date().getTime());
    const localStorageToken = JSON.stringify({
      user: this.userSubject.value,
      lastAccess: new Date().getTime(),
      isSuperAdmin: this.isSuperAdminSubject.value,
      organization: this.organizationSubject.value,
    });
    localStorage.setItem("cultuar", localStorageToken);
  }

  public set organizationObs(organization) {
    this.organizationSubject.next(organization);
  }

  public set userLang(lang: string) {
    this.userSubject.value.lang = lang;
    const localStorageToken = JSON.stringify({
      user: this.userSubject.value,
      lastAccess: new Date().getTime(),
      isSuperAdmin: this.isSuperAdminSubject.value,
      organization: this.organizationSubject.value,
    });
    localStorage.setItem("cultuar", localStorageToken);
  }

  public set userJwtToken(token) {
    this.userSubject.value.token = token;
    const localStorageToken = JSON.stringify({
      user: this.userSubject.value,
      lastAccess: new Date().getTime(),
      isSuperAdmin: this.isSuperAdminSubject.value,
      organization: this.organizationSubject.value,
    });
    localStorage.setItem("cultuar", localStorageToken);
  }

  // tslint:disable-next-line:typedef
  login(email, password) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, {
        email,
        password,
      })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          const isSuperAdmin = user.user.role.slug === "super-admin";
          const localStorageToken = JSON.stringify({
            user: user.user,
            lastAccess: new Date().getTime().toString(),
            isSuperAdmin: isSuperAdmin,
            organization: user.account,
          });

          this.organizationSubject.next(user.account);
          localStorage.setItem("cultuar", localStorageToken);
          this.store.dispatch(new SetUserInformationAction(user));
          this.isSuperAdminSubject.next(isSuperAdmin);
          this.store.dispatch(new SetIsSuperAdmin(isSuperAdmin));

          !isSuperAdmin &&
            this.store.dispatch(new SetOrganization(user["account"]));

          return user;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // tslint:disable-next-line:typedef
  logout() {
    // remove user from local storage and set current user to null
    this.user = null;
    this.navigationHistoryService.resetHistory();
    localStorage.removeItem("cultuar");
    this.userSubject.next(null);
    this.isSuperAdminSubject.next(false);
    this.organizationSubject.next(null);
    this.lastAccessSubject.next(null);
    this.store.dispatch(new SetUserInformationAction(this.userSubject.value));
    this.store.dispatch(new SetOrganization(""));
    this.store.dispatch(new SetIsSuperAdmin(false));
    this.router.navigate(["/login"]);
  }

  resetPassword(email) {
    return this.http
      .post<User>(`${environment.apiUrl}/users/reset/${email}`, {})
      .pipe(map((result) => result));
  }

  setNewPassword(token, data) {
    return this.http
      .post(`${environment.apiUrl}/users/confirm-reset/${token}`, data)
      .pipe(map((result) => result));
  }

  confirmEmail(token) {
    return this.http
      .post(`${environment.apiUrl}/users/confirm-email/${token}`, "")
      .pipe(map((result) => result));
  }
}
