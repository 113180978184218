import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getAccountTypes() {
    return this.http
      .get<any>(`${environment.apiUrl}/account-types`, {})
      .pipe(map((data) => data));
  }

  getAlertTypes() {
    return this.http
      .get<any>(`${environment.apiUrl}/alert-types`, {})
      .pipe(map((data) => data));
  }

  getLinkTypes() {
    return this.http
      .get<any>(`${environment.apiUrl}/link-types`, {})
      .pipe(map((data) => data));
  }

  getPlans() {
    return this.http
      .get<any>(`${environment.apiUrl}/plans`, {})
      .pipe(map((data) => data));
  }

  getProvinces() {
    return this.http
      .get<any>(`${environment.apiUrl}/zones/provinces`, {})
      .pipe(map((data) => data));
  }

  getRoutesTypes() {
    return this.http
      .get<any>(`${environment.apiUrl}/stage-types`, {})
      .pipe(map((data) => data));
  }

  getSocialTypes() {
    return this.http
      .get<any>(`${environment.apiUrl}/social-network-types`, {})
      .pipe(map((data) => data));
  }

  getPolicy() {
    return this.http
      .get<any>(`${environment.apiUrl}/settings`, {})
      .pipe(map((data) => data));
  }

  savePolicy(id, data) {
    return this.http
      .patch(`${environment.apiUrl}/settings/${id}`, data)
      .pipe(map((data) => data));
  }
}
