import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/store/models/app-state.model";
import { Store } from "@ngrx/store";
import { AuthService } from "../services/auth.service";

@Injectable()
export class OrganizationSelectionGuardService implements CanActivate {
  organizationSelected: any = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.store.subscribe((state: any) => {
      this.organizationSelected = state.AppState.organization;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.organizationSelected) {
      return true;
    }

    // not organization Selected so redirect to organization selection page with the return url
    this.router.navigate(["/dashboard/organizations"]);

    return false;
  }
}
