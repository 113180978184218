import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class UtilsService {
  constructor(private router: Router) {}

  getTextInLanguage(key) {
    const language = "es";

    if (key && key[language]) {
      return key[language];
    } else {
      if (typeof key == "string") {
        return key;
      } else {
        return "no definido";
      }
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  getDate(dateValue) {
    const date = new Date(dateValue);
    return (
      [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/") +
      " " +
      date.getHours() +
      ":" +
      this.padTo2Digits(date.getMinutes())
    );
  }

  getAvailableLanguages() {
    return ["es", "ca", "de", "en", "fr", "it", "vlc"];
  }

  getMB(value) {
    return (value / 1024 / 1024).toFixed(2) + "Mb";
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray) {
        control.markAsTouched();
        control.markAsDirty();
        control.markAllAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  resetAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray) {
        control.markAsUntouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toHoursAndMinutes(totalMinutes) {
    if (totalMinutes) {
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);

      return `${
        this.padTo2Digits(hours) != "00" ? this.padTo2Digits(hours) + "h" : ""
      } ${this.padTo2Digits(minutes)}min`;
    } else {
      return "-";
    }
  }
}
